var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 min-vh"},[_c('div',{staticClass:"auth-inner"},[_c('v-row',{staticClass:"auth-row ma-0"},[_c('v-col',{staticClass:"d-none d-lg-block position-relative overflow-hidden pa-0",attrs:{"lg":"8"}},[_c('div',{staticClass:"side-panel fade-in"},[_c('v-img',{staticClass:"min-vh",attrs:{"src":!_vm.$vuetify.theme.isDark
                ? require('./assets/white-background.jpg')
                : require('./assets/dark-background.png')}},[_c('v-row',{staticClass:"d-flex column justify-center align-center",staticStyle:{"min-height":"100vh"}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},[_c('img',{staticClass:"slide-in-down",attrs:{"src":!_vm.$vuetify.theme.isDark
                      ? require('./assets/dark-logo.svg')
                      : require('./assets/white-logo.svg'),"alt":"Logo"}}),_c('v-chip',{staticClass:"mt-4",staticStyle:{"font-size":"1rem","width":"fit-content"},attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("Assist Mode"))+" ")])],1)],1)],1)],1)]),_c('v-col',{staticClass:"d-flex align-center auth-bg pa-10 pb-0",staticStyle:{"min-height":"100vh"},attrs:{"lg":"4"}},[_c('v-row',[(_vm.valid_token)?_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-card-text',{staticClass:"d-flex flex-column text-start mb-8"},[_c('img',{staticClass:"slide-in-right",staticStyle:{"max-width":"100%"},attrs:{"src":!_vm.$vuetify.theme.isDark
                      ? require('./assets/dark-logo.svg')
                      : require('./assets/white-logo.svg')}})]):_vm._e(),_c('v-card-text',[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" ¡"+_vm._s(_vm.$t("Welcome to"))+" "+_vm._s(_vm.CorporateName)+"! ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Use your admin credentials and access the panel"))+" ")])]),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
return [_c('v-form',{attrs:{"spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin()}}},[_c('ValidationProvider',{attrs:{"name":_vm.toLowerCase(_vm.$t('Email')),"rules":"required|email","debounce":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-6",attrs:{"autofocus":_vm.$vuetify.breakpoint.mdAndUp,"outlined":"","label":_vm.$t('Email'),"placeholder":_vm.$t('Email'),"hide-details":"auto","autocapitalize":"none","error-messages":errors[0]},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.toLowerCase(_vm.$t('Password')),"rules":"required|min:6","debounce":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":_vm.$t('Password'),"placeholder":_vm.$t('Password'),"append-icon":_vm.isPasswordVisible
                            ? _vm.icons.mdiEyeOffOutline
                            : _vm.icons.mdiEyeOutline,"hide-details":"auto","error-messages":errors[0]},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})]}}],null,true)}),(true)?_c('div',{staticClass:"\n                        d-flex\n                        align-center\n                        justify-space-between\n                        flex-wrap\n                      "},[_c('span',[(!false)?_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('v-avatar',{staticClass:"mySwitcher",attrs:{"color":"grey-lighten-2","size":"36"},on:{"click":function($event){return _vm.handleLocale()}}},[_vm._v(" "+_vm._s(_vm.locales.find(function (l) { return l.locale === _vm.$i18n.locale; }) .title)+" ")])],1):_vm._e(),_c('app-bar-theme-switcher',{staticClass:"mx-4"})],1)]):_vm._e(),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","color":"primary","type":"submit","disabled":invalid,"loading":_vm.loading,"id":"login_button_login_page","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")]),_c('br'),_c('span',[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'landing' })}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiArrowLeft)+" ")]),_c('span',{class:_vm.$vuetify.theme.isDark
                              ? 'color-white-text'
                              : 'color-blue-text'},[_vm._v(" "+_vm._s(_vm.$t("GoBack"))+" ")])],1)])],1)]}}],null,false,1816661149)})],1)],1)],1):_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[(_vm.loading)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',[_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)]):_c('div',[_c('v-card-text',[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.$t("We're sorry!"))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "The link has expired. Request a new link to reset enter in assist mode" ))+". ")])])],1)])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }